import $ from 'jquery';
import * as bootstrap from 'bootstrap';

import '../custom/fonts';
import '../custom/bootstrap';
import * as IC from '../custom/in_control';

import { Carousel } from '@fancyapps/ui/dist/carousel/carousel.esm.js';
import { Autoplay } from '@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js';

// Architect region tabs
function paneForTab(tab) {
  const regionId = $(tab).data('region-id');
  return $('#region_tabs_content').find(`.tab-pane[data-region-id="${regionId}"]`);
}

function paneContentVisible(pane, visible) {
  $(pane).find('.architects')[visible ? 'show' : 'hide']();
}

function paneSpinnerVisible(pane, visible) {
  $(pane).find('.spinner')[visible ? 'show' : 'hide']();
}

function paneContentReplace(pane, data) {
  $(pane).find('.architects').html(data);
}

function paneBeforeContentReplace(pane) {
  paneContentVisible(pane, false);
  paneSpinnerVisible(pane, true);
}

function paneAfterContentReplace(pane) {
  paneSpinnerVisible(pane, false);
  paneContentVisible(pane, true);
}

function activeRegionTab() {
  return $('.region-tabs .nav-link.active');
}

function activeRegionId() {
  return activeRegionTab().data('region-id');
}

// Architect prefecture tabs
function prefecturePaneForTab(tab) {
  const prefectureId = $(tab).data('prefecture-id');
  return $('.prefecture-tabs-content').find(`.tab-pane[data-prefecture-id="${prefectureId}"]`);
}

function prefecturePaneContentVisible(pane, visible) {
  $(pane).find('.prefecture-architects')[visible ? 'show' : 'hide']();
}

function prefecturePaneSpinnerVisible(pane, visible) {
  $(pane).closest('.region-tab-content').find('.spinner')[visible ? 'show' : 'hide']();
}

function prefecturePaneContentReplace(pane, data) {
  $(pane).find('.prefecture-architects').html(data);
}

function prefecturePaneBeforeContentReplace(pane) {
  architectSortEnable(false);
  prefecturePaneContentVisible(pane, false);
  prefecturePaneSpinnerVisible(pane, true);
}

function prefecturePaneAfterContentReplace(pane) {
  prefecturePaneSpinnerVisible(pane, false);
  prefecturePaneContentVisible(pane, true);
  architectSortEnable(true);
}

function activePrefectureTab() {
  return $('.region-tab-content.active .prefecture-tabs .nav-link.active');
}

function doUpdatePrefecturePane(pane, regionId, prefectureId) {
  prefecturePaneBeforeContentReplace(pane);

  $.ajax({
    type: 'get',
    url:  `/public/architects?for_prefecture=true&region_id=${regionId}&prefecture_id=${prefectureId}&sort=${architectSort()}`
  }).done(function(data) {
    prefecturePaneContentReplace(pane, data);
  }).always(function() {
    prefecturePaneAfterContentReplace(pane);
  });
}

// Sort
function architectSort() {
  return $('#sort').val();
}

function architectSortEnable(enabled) {
  $('#sort').attr('disabled', !enabled);
}

function initializeSort() {
  $(document).on('change', '#sort', function(evt) {
    const pane         = prefecturePaneForTab(activePrefectureTab()),
          prefectureId = pane.data('prefecture-id'),
          regionId     = activeRegionId();
    doUpdatePrefecturePane(pane, regionId, prefectureId);
  });
}


// Region tabs
function initializeRegionTabs() {
  $(document).on('shown.bs.tab', function(evt) {
    const pane     = paneForTab(evt.target),
          regionId = pane.data('region-id');
    paneBeforeContentReplace(pane);

    $.ajax({
      type: 'get',
      url:  `/public/architects?region_id=${regionId}&sort=${architectSort()}`
    }).done(function(data) {
      paneContentReplace(pane, data);
    }).always(function() {
      paneAfterContentReplace(pane);
    });
  });
}

// Prefecture tabs
function initializePrefectureTabs() {
  $(document).on({
    'shown.bs.tab': function(evt) {
      const pane         = prefecturePaneForTab(evt.target),
            prefectureId = pane.data('prefecture-id'),
            regionPane   = $(evt.target).closest('.tab-pane'),
            regionId     = regionPane.data('region-id');
      doUpdatePrefecturePane(pane, regionId, prefectureId);
    }
  }, '.prefecture-tabs');
}

// Architect page pagination
function initializePrefecturePagination() {
  $('#region_tabs_content').on({
    'ajax:before': function() {
      let pane = $(this).closest('.tab-pane');
      prefecturePaneBeforeContentReplace(pane);
    },
    'ajax:success': function(event) {
      const [response, status, xhr] = event.detail;
      let pane = $(this).closest('.tab-pane');
      prefecturePaneContentReplace(pane, xhr.response);
      prefecturePaneAfterContentReplace(pane);
    }
  }, '.prefecture-tabs-content .pagination .page-link');
}

// Architect details modal
function initializeArchitectDetails() {
  const modal = $('#architect_modal');
  if(!modal.get(0)) return;

  const modalHandle = new bootstrap.Modal('#architect_modal', {});

  $('#region_tabs_content').on('click', '.card.architect', function(evt) {
    let card = $(this),
        architectId   = card.data('architect-id'),
        architectName = card.find('.architect-name');

    let modalArchitectName    = modal.find('#architect_name'),
        modalArchitectAtelier = modal.find('#architect_atelier');
    modalArchitectName.html(architectName.html());
    modalArchitectAtelier.hide();

    let pane = card.closest('.tab-pane');
    prefecturePaneSpinnerVisible(pane, true);

    $.ajax({
      type: 'get',
      url:  `/public/architect/${architectId}`
    }).done(function(data) {
      let modalBody = modal.find('.modal-body');
      modalBody.html(data);

      let atelierName = modalBody.find('.architect-details').data('architect-atelier');
      if(atelierName) {
        modalArchitectAtelier.html(` ／ ${atelierName}`);
        modalArchitectAtelier.show();
      }

      modalHandle.show();
    }).always(function() {
      prefecturePaneSpinnerVisible(pane, false);
    });
  });
}

// Fancyapps
function initializeCarousel() {
  const carousels = $('.f-carousel');

  if(!carousels.length)
    return;

  carousels.each(function(ci, container) {
    new Carousel(container, {
      infinite:   true,
      transition: 'crossfade',
      Autoplay:   {
        autoStart:    true,
        showProgress: true,
        timeout:      5000
      },
      on:         {
        initLayout: function() {
          $(container).css('visibility', 'visible');
        }
      }
    }, { Autoplay });
  });
}

// Scroll back to top
function initializeScrollToTop() {
  const button = $('#scroll_to_top');

  function scrollFunction() {
    let visible = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20;
    button[visible ? 'show' : 'hide']();
  }

  window.onscroll = function () {
    scrollFunction();
  };

  button.on('click', function() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });
}


// ASJreform page initializations
function icInitializeBootstrapMain() {
  initializeSort();
  initializeRegionTabs();
  initializePrefectureTabs();
  initializePrefecturePagination();
  initializeArchitectDetails();
  initializeCarousel();
  initializeScrollToTop();
}

window.icInitializeBootstrapMain = icInitializeBootstrapMain;

window.waitFor(
  function() {
    jQuery(function() {
      IC.icPageInitialize();
    });
  },
  'jquery'
);
